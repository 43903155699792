import axios from "axios";
import {
  BinanceEndpoint,
  BlockCypherEndpoint,
  BTCComEndpoint,
  DefiLlamaEndpoint,
} from "../config";

/**
 * Third-Party API Requests
 */

//------------------- Helpers ---------------------------
function parseBlockCypherTime(ts: string): Date {
  const cleanedTs = ts.split("Z")[0].split(".")[0];
  return new Date(cleanedTs);
}

// TODO: We cannot implement this within this codebase because of CORS issues;
export async function getBinanceHeight(
  chain: string,
  ts: number,
  thornodeHeight: number
): Promise<ChainHeight | null> {
  const API_LIMIT = 50;
  const AVG_BLOCK_SEC = 0.43;
  const DELTA_PRECISION = Math.round(API_LIMIT * AVG_BLOCK_SEC);

  let params = {
    startHeight: thornodeHeight,
    endHeight: thornodeHeight + 1,
  };
  const formatParams = (params: any) => {
    return Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");
  };

  try {
    let res = await axios.get(
      `${BinanceEndpoint}/api/v1/blocks?${formatParams(params)}`
    );
    if (res.status !== 200) {
      return null;
    }

    let data = res.data["blocks"][0];
    let newTs = Math.floor(data["time"] / 1000);
    let newHeight = thornodeHeight;
    let deltaSec = newTs - ts;

    while (Math.abs(deltaSec) > DELTA_PRECISION) {
      let deltaHeight = Math.floor(deltaSec / AVG_BLOCK_SEC) * -1;
      let mid = newHeight + deltaHeight;
      let low = mid - 25;
      let high = mid + 25;
      params.startHeight = low;
      params.endHeight = high;

      res = await axios.get(
        `${BinanceEndpoint}/api/v1/blocks?${formatParams(params)}`
      );

      if (res.status !== 200) {
        break;
      }

      let data = res.data["blocks"];
      let updatedHeight = false;

      for (let block of data) {
        if (Math.floor(block["time"] / 1000) >= ts) {
          newTs = Math.floor(block["time"] / 1000);
          newHeight = block["height"];
          deltaSec = newTs - ts;
          updatedHeight = true;
          break;
        }
      }

      if (updatedHeight) {
        continue;
      }

      newTs = data[data.length - 1]["time"] / 1000;
      deltaSec = newTs - ts;
    }

    return {
      chain,
      height: newHeight,
      last_observed_height: thornodeHeight,
      delta_height: newHeight - thornodeHeight,
      source: "Binance",
    };
  } catch (err) {
    console.error(err);
    return null;
  }
}

// TODO: We cannot implement this within this codebase because of CORS issues;
export async function getBlockCypherHeight(
  chain: string,
  ts: number,
  thornodeHeight: number
): Promise<ChainHeight | null> {
  const DELTA_PRECISION = 60 * 6; // Arbitrary 6 minutes representing a TC block.

  try {
    // If THORNode's observed chain height is within DELTA_PRECISION of
    // the chain's block timestamp, it's close enough.
    const res1 = await axios.get(
      `${BlockCypherEndpoint}/v1/${chain.toLowerCase()}/main/blocks/${thornodeHeight}`
    );
    const data1 = res1.data;
    const thornodeTs = parseBlockCypherTime(data1.time);

    if (Math.abs(ts - thornodeTs.getTime()) <= DELTA_PRECISION) {
      return {
        chain,
        height: thornodeHeight,
        last_observed_height: thornodeHeight,
        delta_height: thornodeHeight - thornodeHeight,
        source: "BlockCypher",
      };
    }

    // Otherwise, the chain height is too stale and we should search for
    // a closer block by using an interpolation search, starting from the
    // first and last observed block of the chain.
    const res2 = await axios.get(
      `${BlockCypherEndpoint}/v1/${chain.toLowerCase()}/main`
    );
    const data2 = res2.data;
    const lastHeight = data2.height;
    const lastTs = parseBlockCypherTime(data2.time);
    let firstHeight = 1;
    const res3 = await axios.get(
      `${BlockCypherEndpoint}/v1/${chain.toLowerCase()}/main/blocks/${firstHeight}`
    );
    const data3 = res3.data;
    let firstTs = parseBlockCypherTime(data3.time);
    const deltaTarget = lastTs.getTime() - ts;

    while (
      Math.abs(ts - firstTs.getTime()) > DELTA_PRECISION ||
      firstHeight < thornodeHeight
    ) {
      const deltaRange = lastTs.getTime() - firstTs.getTime();
      const deltaPct = 1 - deltaTarget / deltaRange;
      firstHeight += Math.floor(deltaPct * (lastHeight - firstHeight));
      const res4 = await axios.get(
        `${BlockCypherEndpoint}/v1/${chain.toLowerCase()}/main/blocks/${firstHeight}`
      );
      const data4 = res4.data;
      firstTs = parseBlockCypherTime(data4.time);
    }

    return {
      chain,
      height: firstHeight,
      last_observed_height: thornodeHeight,
      delta_height: firstHeight - thornodeHeight,
      source: "BlockCypher",
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getBTCComHeight(
  chain: string,
  ts: number,
  thornodeHeight: number
): Promise<ChainHeight | null> {
  try {
    const source = "BTC.com";
    const date = new Date(ts * 1000).toISOString().split("T")[0];
    const url = `${BTCComEndpoint}/v3/block/date`;
    const res = await axios.get(`${url}/${date}`);
    let data = res.data.data;

    while (data[data.length - 1].timestamp > ts) {
      const prevDate = new Date(date);
      prevDate.setDate(prevDate.getDate() - 1);
      const prevDateStr = prevDate.toISOString().split("T")[0];
      const prevRes = await axios.get(`${url}/${prevDateStr}`);
      data = prevRes.data.data;
    }

    for (const block of data) {
      if (block.timestamp <= ts) {
        return {
          chain,
          height: block.height,
          last_observed_height: thornodeHeight,
          delta_height: block.height - thornodeHeight,
          source,
        };
      }
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function getDefiLlamaHeight(
  chain: string,
  ts: number,
  thornodeHeight: number
): Promise<ChainHeight | null> {
  const DEFILLAMA_CHAIN_MAP: Record<string, string> = {
    AVAX: "avax",
    ETH: "ethereum",
  };

  try {
    const source = "DefiLlama";
    const res = await axios.get(
      `${DefiLlamaEndpoint}/block/${DEFILLAMA_CHAIN_MAP[chain]}/${ts}`
    );
    const data = res.data;
    return {
      chain,
      height: data.height,
      last_observed_height: thornodeHeight,
      delta_height: data.height - thornodeHeight,
      source,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}
