import axios from "axios";
import { useEffect, useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTitle,
  EuiInMemoryTable,
  EuiHealth,
  EuiCode,
  EuiSpacer,
  EuiLink,
  EuiSearchBarProps,
} from "@elastic/eui";

import { APIEndpoint } from "../config";

export default function Security() {
  // ------------------------------ supply ------------------------------

  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    const run = async () => {
      const imagesRes = await axios.get(
        `${APIEndpoint}/thorchain/security/images`
      );
      setImages(
        imagesRes.data.map((image: any) => ({
          ...image,
          modified:
            image.previous_hash !== "" && image.hash !== image.previous_hash,
        }))
      );
    };
    run();
  }, []);

  const imagesColumns = [
    {
      field: "repo",
      name: "Repo",
      width: "120px",
      sortable: true,
      render: (repo: string) => (
        <EuiLink href={`https://gitlab.com/${repo}`}>
          {repo.substring(repo.lastIndexOf("/") + 1)}
        </EuiLink>
      ),
    },
    {
      field: "hash",
      name: "Hash",
      width: "600px",
      render: (hash: string) => <EuiCode>{hash}</EuiCode>,
    },
    {
      field: "tag",
      name: "Tag",
      sortable: true,
      render: (hash: string) => <EuiCode>{hash}</EuiCode>,
    },
    {
      field: "modified",
      name: "Modified",
      width: "160px",
      render: (modified: boolean) => (
        <EuiHealth color={modified ? "warning" : "success"}>
          {modified ? "Modified" : "Unmodified"}
        </EuiHealth>
      ),
    },
  ];

  const search: EuiSearchBarProps = {
    box: {
      incremental: true,
    },
    filters: [
      {
        type: "field_value_selection",
        field: "modified",
        name: "Modified",
        options: [
          {
            value: true,
            name: "Modified",
            view: "Modified",
          },
          {
            value: false,
            name: "Unmodified",
            view: "Unmodified",
          },
        ],
      },
    ],
  };

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiPanel>
          <EuiTitle>
            <h2>Images</h2>
          </EuiTitle>
          <EuiSpacer />
          <EuiInMemoryTable
            tableCaption="Images"
            items={images}
            columns={imagesColumns}
            pagination={{ pageSize: 20, showPerPageOptions: false }}
            search={search}
            sorting={true}
          />
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
