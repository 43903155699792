import axios from "axios";
import { useEffect, useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiCode,
  EuiHealth,
  EuiHorizontalRule,
  EuiInMemoryTable,
  EuiTitle,
} from "@elastic/eui";

import { ThornodeEndpoint, MidgardEndpoint } from "../config";

export default function Health() {
  //const [invariants, setInvariants] = useState([]);

  // ------------------------------ midgard pool deviations ------------------------------

  const [midgardPoolDeviations, setMidgardPoolDeviations] = useState([]);

  useEffect(() => {
    const run = async () => {
      // fetch thornode and midgard pools
      const [thornodePools, midgardPools] = await Promise.all([
        axios.get(`${ThornodeEndpoint}/thorchain/pools`),
        axios.get(`${MidgardEndpoint}/v2/pools`),
      ]);

      // index for comparison
      const thornodePoolsMap: any = {};
      thornodePools.data.forEach((pool: any) => {
        thornodePoolsMap[pool.asset] = pool;
      });
      const midgardPoolsMap: any = {};
      midgardPools.data.forEach((pool: any) => {
        midgardPoolsMap[pool.asset] = pool;
      });

      const fields = [
        {
          thornode: "balance_rune",
          midgard: "runeDepth",
        },
        {
          thornode: "balance_asset",
          midgard: "assetDepth",
        },
        {
          thornode: "LP_units",
          midgard: "liquidityUnits",
        },
        {
          thornode: "pool_units",
          midgard: "units",
        },
        {
          thornode: "synth_units",
          midgard: "synthUnits",
        },
        {
          thornode: "synth_supply",
          midgard: "synthSupply",
        },
      ];

      // compute the diff
      const diff: any = [];
      Object.values(thornodePoolsMap).forEach((tp: any) => {
        const mp = midgardPoolsMap[tp.asset];

        fields.forEach((field: any) => {
          if (tp[field.thornode] !== mp[field.midgard]) {
            diff.push({
              asset: tp.asset,
              field: field.midgard,
              thornode: tp[field.thornode],
              midgard: mp[field.midgard],
              diff_percent:
                Math.abs(1 - tp[field.thornode] / mp[field.midgard]) * 100,
            });
          }
        });
      });

      setMidgardPoolDeviations(diff);
    };

    run();
  }, []);

  const midgardPoolDeviationsColumns = [
    {
      field: "asset",
      name: "Asset",
      width: "50px",
      sortable: true,
      render: (asset: string) => asset.split("-")[0],
    },
    {
      field: "field",
      name: "Field",
      width: "50px",
      sortable: true,
      render: (field: string) => <EuiCode>{field}</EuiCode>,
    },
    {
      field: "thornode",
      name: "Thornode",
      width: "80px",
      sortable: true,
      render: (thornode: string) => parseInt(thornode).toLocaleString("en-US"),
    },
    {
      field: "midgard",
      name: "Midgard",
      width: "80px",
      sortable: true,
      render: (midgard: string) => parseInt(midgard).toLocaleString("en-US"),
    },
    {
      field: "diff_percent",
      name: "Difference",
      width: "50px",
      sortable: true,
      render: (diff_percent: number) => `${diff_percent.toFixed(6)}%`,
    },
  ];

  const midgardPoolDeviationsSorting: any = {
    sort: {
      field: "diff_percent",
      direction: "desc",
    },
  };

  // ------------------------------ status checks ------------------------------

  const [statusChecks, setStatusChecks] = useState<Array<any>>([]);

  useEffect(() => {
    const run = async () => {
      const checks = [
        {
          name: "thornode.ninerealms.com",
          endpoint: "https://thornode.ninerealms.com/thorchain/ping",
        },
        {
          name: "rpc.ninerealms.com",
          endpoint: "https://rpc.ninerealms.com/health",
        },
        {
          name: "midgard.ninerealms.com",
          endpoint: "https://midgard.ninerealms.com/v2/health",
        },
      ];

      const statuses: any = await Promise.all(
        checks.map(async (check) => {
          return axios.get(check.endpoint).catch((e) => {
            return { status: "unknown" };
          });
        })
      );

      setStatusChecks(
        checks.map((check, i) => ({
          ...check,
          status: statuses[i].status,
        }))
      );
    };

    run();
  }, []);

  const statusChecksColumns = [
    {
      field: "name",
      name: "Endpoint",
      width: "80px",
      sortable: true,
    },
    {
      field: "status",
      name: "Status",
      width: "30px",
      sortable: true,
      render: (status: any) => (
        <EuiHealth color={status === 200 ? "success" : "danger"}>
          {status}
        </EuiHealth>
      ),
    },
  ];

  return (
    <EuiFlexGroup direction="column" gutterSize="m">
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="m">
          <EuiFlexItem>
            <EuiPanel>
              <EuiTitle size="s">
                <h2>Status Checks</h2>
              </EuiTitle>
              <EuiHorizontalRule />
              <EuiInMemoryTable
                tableCaption="Status Checks"
                items={statusChecks}
                columns={statusChecksColumns}
                pagination={false}
                //sorting={midgardPoolDeviationsSorting}
                style={{ height: "100%" }}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s">
                <h2>Thornode Invariants</h2>
              </EuiTitle>
              <EuiHorizontalRule />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="m">
          <EuiFlexItem grow={3}>
            <EuiPanel>
              <EuiTitle size="s">
                <h2>Midgard Pool Deviations</h2>
              </EuiTitle>
              <EuiHorizontalRule />
              <EuiInMemoryTable
                tableCaption="Midgard Pools Deviations"
                items={midgardPoolDeviations}
                columns={midgardPoolDeviationsColumns}
                pagination={false}
                sorting={midgardPoolDeviationsSorting}
                style={{ height: "100%" }}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
