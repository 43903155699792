import {
  EuiPanel,
  EuiLink,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@elastic/eui";

export default function Links() {
  return (
    <EuiFlexGroup direction="column" gutterSize="m">
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="s">
                <h2>Thorchain</h2>
              </EuiTitle>
              <EuiTitle size="xs">
                <h3>Documentation</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://dev.thorchain.org">
                    https://dev.thorchain.org
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://docs.thorchain.org">
                    https://docs.thorchain.org
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://rpc.thorchain.info">
                    https://rpc.thorchain.info
                  </EuiLink>
                </li>
              </ul>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="s">
                <h2>Nine Realms</h2>
              </EuiTitle>
              <EuiTitle size="xs">
                <h3>Thorchain Endpoints</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://midgard.ninerealms.com">
                    https://midgard.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://thornode.ninerealms.com">
                    https://thornode.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://rpc.ninerealms.com">
                    https://rpc.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://thornode-v0.ninerealms.com">
                    https://thornode-v0.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://rpc-v0.ninerealms.com">
                    https://rpc-v0.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://thornode-archive.ninerealms.com">
                    https://thornode-archive.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://rpc-archive.ninerealms.com">
                    https://rpc-archive.ninerealms.com
                  </EuiLink>
                </li>
              </ul>
              <br />
              <EuiTitle size="xs">
                <h3>Dashboards</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://dashboards.ninerealms.com">
                    https://dashboards.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://dashboards.dev.ninerealms.com">
                    https://dashboards.dev.ninerealms.com
                  </EuiLink>
                </li>
              </ul>
              <br />
              <EuiTitle size="xs">
                <h3>Research and Utilities</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://earnings.dev.ninerealms.com">
                    https://earnings.dev.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://notebooks.ninerealms.com">
                    https://notebooks.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://simulator.dev.ninerealms.com">
                    https://simulator.dev.ninerealms.com
                  </EuiLink>
                </li>
              </ul>
              <br />
              <EuiTitle size="xs">
                <h3>APIs</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://api.ninerealms.com">
                    https://api.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://haskoin.ninerealms.com/btc">
                    https://haskoin.ninerealms.com/btc
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://haskoin.ninerealms.com/bch">
                    https://haskoin.ninerealms.com/bch
                  </EuiLink>
                </li>
              </ul>
              <br />
              <EuiTitle size="xs">
                <h3>Thorchain Stagenet Endpoints</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://stagenet-midgard.ninerealms.com">
                    https://stagenet-midgard.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://stagenet-thornode.ninerealms.com">
                    https://stagenet-thornode.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://stagenet-rpc.ninerealms.com">
                    https://stagenet-rpc.ninerealms.com
                  </EuiLink>
                </li>
              </ul>
              <br />
              <EuiTitle size="xs">
                <h3>Chain Endpoints</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://bitcoin.ninerealms.com">
                    https://bitcoin.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://bitcoin-cash.ninerealms.com">
                    https://bitcoin-cash.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://litecoin.ninerealms.com">
                    https://litecoin.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://dogecoin.ninerealms.com">
                    https://dogecoin.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://ethereum.ninerealms.com">
                    https://ethereum.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://gaia.ninerealms.com">
                    https://gaia.ninerealms.com
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://avalanche.ninerealms.com">
                    https://avalanche.ninerealms.com
                  </EuiLink>
                </li>
              </ul>
              <br />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="s">
                <h2>Community</h2>
              </EuiTitle>
              <EuiTitle size="xs">
                <h3>Dashboards</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://thorchain.network">
                    https://thorchain.network
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://thorchain.live/thorchain/chaosnet">
                    https://thorchain.live/thorchain/chaosnet
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://thorchain.net/dashboard">
                    https://thorchain.net/dashboard
                  </EuiLink>
                </li>
              </ul>
              <br />
              <EuiTitle size="xs">
                <h3>Explorers</h3>
              </EuiTitle>
              <ul>
                <li>
                  <EuiLink href="https://runescan.io">
                    https://runescan.io
                  </EuiLink>
                </li>
                <li>
                  <EuiLink href="https://thorchain.net/transactions">
                    https://thorchain.net/transactions
                  </EuiLink>
                </li>
              </ul>
              <br />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
