import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiInMemoryTable,
  EuiHealth,
  EuiSearchBarProps,
  EuiSearchBarOnChangeArgs,
  EuiCode,
} from "@elastic/eui";

import { APIEndpoint } from "../config";

const addressExplorers: any = {
  ETH: "https://etherscan.io/address",
  BNB: "https://explorer.binance.org/address",
  BTC: "https://bitinfocharts.com/bitcoin/address",
  BCH: "https://bitinfocharts.com/bitcoin%20cash/address",
  LTC: "https://bitinfocharts.com/litecoin/address",
  DOGE: "https://bitinfocharts.com/dogecoin/address",
  GAIA: "https://www.mintscan.io/cosmos/account",
  AVAX: "https://snowtrace.io/address",
  BSC: "https://bscscan.com/address",
};

export default function Vaults() {
  // set state from url params
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const currentSearch: string = params.has("search")
    ? `${params.get("search")}`
    : "solvent:(insolvent or oversolvent)";

  // polyfill for address encoder
  (window as any).Buffer = (window as any).Buffer || require("buffer").Buffer;
  const { formatsByName } = require("@ensdomains/address-encoder");

  // ------------------------------ supply ------------------------------

  const [asgard, setAsgard] = useState<any>([]);
  const [balances, setBalances] = useState<any>([]);

  useEffect(() => {
    const run = async () => {
      const [asgardRes] = await Promise.all([
        axios.get(`${APIEndpoint}/thorchain/solvency/asgard`),
      ]);
      setAsgard(asgardRes.data);

      let bals: any = [];
      asgardRes.data.forEach((vault: any) => {
        if (!["ActiveVault", "Active"].includes(vault.status)) {
          return;
        }

        const addresses = vault.addresses.reduce((acc: any, addr: any) => {
          acc[addr.chain] = addr.address;
          return acc;
        }, {});

        vault.coins.forEach((coin: any) => {
          coin.amount = coin.amount ? parseInt(coin.amount) : null;
          coin.chain_amount = coin.chain_amount
            ? parseInt(coin.chain_amount)
            : null;

          let solvent = "unknown";
          if (coin.amount === coin.chain_amount) {
            solvent = "solvent";
          } else if (coin.chain_amount && coin.amount > coin.chain_amount) {
            solvent = "insolvent";
          } else if (coin.chain_amount && coin.amount < coin.chain_amount) {
            solvent = "oversolvent";
          }

          const chain = coin.asset.split(".")[0];
          let address = addresses[chain];

          // convert BCH address for explorer
          if (chain === "BCH") {
            address = formatsByName["BCH"].decoder(`bitcoincash:${address}`);
            address = formatsByName["BCD"].encoder(address);
          }

          bals.push({
            pub_key: vault.pub_key,
            asset: coin.asset,
            amount: coin.amount,
            chain_amount: coin.chain_amount && coin.chain_amount,
            decimals: coin.decimals || 8,
            solvent: solvent,
            difference: coin.chain_amount && coin.chain_amount - coin.amount,
            percent: ["insolvent", "oversolvent"].includes(solvent)
              ? (coin.chain_amount - coin.amount) / coin.chain_amount
              : null,
            address: address,
            explorer: addressExplorers[chain]
              ? `${addressExplorers[chain]}/${address}`
              : null,
            status: vault.status,
          });
        });
      });

      setBalances(bals);
    };
    run();
  }, [formatsByName]);

  const solventColor: any = {
    solvent: "success",
    insolvent: "danger",
    oversolvent: "warning",
  };

  const columns = [
    {
      field: "solvent",
      width: "30px",
      render: (solvent: string) =>
        solvent !== "unknown" && <EuiHealth color={solventColor[solvent]} />,
    },
    {
      field: "asset",
      name: "Asset",
      width: "120px",
      render: (asset: string) => asset.split("-")[0],
      sortable: true,
    },
    {
      field: "pub_key",
      name: "Vault",
      width: "80px",
      render: (pk: any) =>
        pk && <EuiCode>{pk.substring(pk.length - 4, pk.length)}</EuiCode>,
    },
    {
      field: "amount",
      name: "Thorchain Amount",
      render: (amount: string) => parseInt(amount).toLocaleString("en-US"),
      sortable: true,
    },
    {
      field: "chain_amount",
      name: "Actual Amount",
      render: (amount: string) =>
        amount && parseInt(amount).toLocaleString("en-US"),
      sortable: true,
    },
    {
      field: "difference",
      name: "Difference",
      render: (amount: string) =>
        amount && parseInt(amount).toLocaleString("en-US"),
      sortable: true,
    },
    {
      field: "percent",
      name: "Percent",
      render: (percent: number) =>
        Math.abs(percent) > 0 && `${(percent * 100).toFixed(3)}%`,
      sortable: true,
    },
    {
      field: "explorer",
      name: "Explorer",
      width: "130px",
      render: (explorer: string) =>
        explorer && (
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiFlexItem grow={false}>
              <EuiButton size="s" href={explorer}>
                Explorer
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
      sortable: true,
    },
  ];

  const search: EuiSearchBarProps = {
    query: currentSearch,
    onChange: (query: EuiSearchBarOnChangeArgs) => {
      params.set("search", query.queryText);
      history.push(`/vaults?${params.toString()}`);
    },
    box: {
      incremental: true,
    },
    filters: [
      asgard && {
        type: "field_value_selection",
        field: "asset",
        name: "Asset",
        multiSelect: "or",
        options: Object.values(
          (asgard[0]?.coins || []).reduce((acc: any, coin: any) => {
            const asset = coin.asset.split("-")[0];
            acc[asset] = {
              value: coin.asset,
              name: asset,
              view: asset,
            };
            return acc;
          }, {}),
        ),
      },
      asgard && {
        type: "field_value_selection",
        field: "pub_key",
        name: "Vault",
        multiSelect: "or",
        options: asgard.map((vault: any) => ({
          name: vault.pub_key?.substring(
            vault.pub_key.length - 4,
            vault.pub_key.length,
          ),
          value: vault.pub_key,
        })),
      },
      {
        type: "field_value_selection",
        field: "solvent",
        name: "Solvent",
        multiSelect: "or",
        options: [
          {
            value: "solvent",
            name: "Solvent",
            view: "Solvent",
          },
          {
            value: "insolvent",
            name: "Insolvent",
            view: "Insolvent",
          },
          {
            value: "oversolvent",
            name: "Over Solvent",
            view: "Over Solvent",
          },
          {
            value: "unknown",
            name: "Unknown",
            view: "Unknown",
          },
        ],
      },
    ],
  };

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiPanel>
          <EuiInMemoryTable
            tableCaption="Vault Balances"
            items={balances}
            columns={columns}
            search={search}
            sorting={true}
            pagination={{ pageSize: 20, showPerPageOptions: false }}
          />
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
