import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiInMemoryTable,
  EuiHealth,
  EuiButtonIcon,
  EuiTitle,
  EuiSpacer,
  EuiCode,
  EuiCodeBlock,
  EuiModal,
  EuiButton,
  EuiModalBody,
  EuiModalFooter,
  EuiSearchBarProps,
  EuiComboBox,
  EuiSearchBarOnChangeArgs,
} from "@elastic/eui";

import { APIEndpoint, ThornodeEndpoint, RPCEndpoint } from "../config";

const defaultColumns = [
  "Node",
  "Total Bond",
  "Location",
  "Rewards",
  "Slash Points",
  "BCH",
  "BNB",
  "BTC",
  "DOGE",
  "ETH",
  "LTC",
  "GAIA",
  "AVAX",
];

export default function Nodes() {
  // ------------------------------ url params ------------------------------

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const [filterColumns, setFilterColumns] = useState(
    params.get("columns")?.split(",") || defaultColumns,
  );
  const setFilterColumnsHistory = (cols: any) => {
    setFilterColumns(cols);
    params.set("columns", cols.join(","));
    history.push(`/nodes?${params.toString()}`);
  };
  const currentSearch: string = params.has("search")
    ? `${params.get("search")}`
    : "status:(Active)";

  // ------------------------------ nodes data ------------------------------

  const [nodes, setNodes] = useState([]);
  const [status, setStatus] = useState<any>([]);

  useEffect(() => {
    const run = async () => {
      const [nodesRes, statusRes] = await Promise.all([
        axios.get(`${ThornodeEndpoint}/thorchain/nodes`),
        axios.get(`${RPCEndpoint}/status`),
      ]);
      setStatus(statusRes.data.result);

      const nodesData = nodesRes.data.filter(
        (node: any) => node.node_address !== "",
      );

      // find the tip observation of each chain
      const chainTips: any = {};
      nodesData.forEach((node: any) => {
        (node.observe_chains || []).forEach(
          ({ chain, height }: { chain: string; height: number }) => {
            chainTips[chain] = Math.max(chainTips[chain] || 0, height);
          },
        );
      });

      // fetch ip location data
      const locationLookups = nodesData.map((node: any) => {
        if (node.ip_address) {
          return axios.get(`${APIEndpoint}/util/ip/${node.ip_address}`);
        } else {
          return Promise.resolve(null);
        }
      });
      const locations: any = await Promise.all(locationLookups);

      setNodes(
        nodesData.map((node: any, i: number) => {
          // set the chain lag
          if (node.status !== "Active" || node.observe_chains == null) {
            node["bch"] = null;
            node["bnb"] = null;
            node["btc"] = null;
            node["doge"] = null;
            node["eth"] = null;
            node["ltc"] = null;
            node["gaia"] = null;
            node["avax"] = null;
          } else {
            node.observe_chains.forEach(
              ({ chain, height }: { chain: string; height: number }) => {
                node[chain.toLowerCase()] = -(chainTips[chain] - height) || 0;
              },
            );
          }

          // extract the public key
          if (node.pub_key_set.hasOwnProperty("secp256k1")) {
            node.pub_key = node.pub_key_set.secp256k1;
          } else {
            node.pub_key = null;
          }

          // set the location
          node.location =
            locations[i] &&
            `${locations[i].data.regionName}, ${locations[i].data.countryCode}`;
          node.isp = locations[i] && locations[i].data.isp;

          // set index to use for modal
          node.idx = i;

          return node;
        }),
      );
    };
    run();
  }, []);

  // ------------------------------ modal ------------------------------

  // modal with full node data
  const [nodeModalIdx, setNodeModalIdx] = useState(-1);
  const [nodeModal, setNodeModal] = useState<any>([]);

  useEffect(() => {
    const run = async () => {
      if (nodeModalIdx < 0) {
        setNodeModal([]);
        return;
      }

      const closeModal = () => setNodeModalIdx(-1);

      setNodeModal(
        <EuiModal maxWidth={1200} onClose={closeModal}>
          <EuiSpacer size="s" />
          <EuiModalBody>
            <div>
              <EuiTitle>
                <h3>Node</h3>
              </EuiTitle>
              <EuiSpacer size="s" />
              <EuiCodeBlock
                isCopyable={true}
                whiteSpace="pre"
                paddingSize={"s"}
                language="json"
              >
                {JSON.stringify(nodes[nodeModalIdx], null, 2)}
              </EuiCodeBlock>
            </div>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>,
      );
    };
    run();
  }, [nodes, nodeModalIdx]);

  // ------------------------------ display ------------------------------

  // format table columns
  const columns = [
    {
      field: "idx",
      name: "",
      width: "40px",
      render: (idx: number) => (
        <EuiButtonIcon iconType="search" onClick={() => setNodeModalIdx(idx)} />
      ),
    },
    {
      field: "node_address",
      name: "Node",
      render: (na: string) => (
        <EuiCode>{na.substring(na.length - 4, na.length)}</EuiCode>
      ),
      sortable: true,
      width: "80px",
    },
    {
      field: "pub_key",
      name: "Pub Key",
      render: (pk: any) =>
        pk && <EuiCode>{pk.substring(pk.length - 4, pk.length)}</EuiCode>,
      sortable: true,
      width: "80px",
    },
    {
      field: "version",
      name: "Version",
      sortable: true,
      width: "80px",
    },
    {
      field: "isp",
      name: "ISP",
      sortable: true,
      truncateText: true,
    },
    {
      field: "total_bond",
      name: "Total Bond",
      render: (bond: string) =>
        `ᚱ${Math.round(parseInt(bond) / 100000000).toLocaleString("en-US")}`,
      sortable: ({ bond }: { bond: string }) => parseInt(bond),
      truncateText: true,
    },
    {
      field: "location",
      name: "Location",
      sortable: true,
      truncateText: true,
    },
    {
      field: "ip_address",
      name: "IP Address",
      sortable: true,
      truncateText: true,
    },
    {
      field: "bond_providers",
      name: "Providers",
      render: (bond_providers: any) => bond_providers.providers.length,
      sortable: true,
    },
    {
      field: "current_award",
      name: "Rewards",
      render: (reward: string) =>
        `ᚱ${Math.round(parseInt(reward) / 100000000).toLocaleString("en-US")}`,
      sortable: true,
      truncateText: true,
    },
    {
      field: "slash_points",
      name: "Slash Points",
      render: (slash: number) => slash,
      sortable: true,
      truncateText: true,
    },
    {
      field: "active_block_height",
      name: "Block Age",
      render: (block: number) => {
        return parseInt(status.sync_info.latest_block_height) - block;
      },
      sortable: true,
      truncateText: true,
    },
    {
      field: "status",
      name: "Status",
      render: (ns: string) => {
        switch (ns) {
          case "Active":
            return <EuiHealth color="success">{ns}</EuiHealth>;
          case "Standby":
            return <EuiHealth color="warning">{ns}</EuiHealth>;
        }
        return <EuiHealth color="danger">{ns}</EuiHealth>;
      },
      sortable: true,
      truncateText: true,
    },
    {
      field: "bch",
      name: "BCH",
      sortable: true,
      width: "60px",
    },
    {
      field: "btc",
      name: "BTC",
      sortable: true,
      width: "60px",
    },
    {
      field: "ltc",
      name: "LTC",
      sortable: true,
      width: "60px",
    },
    {
      field: "eth",
      name: "ETH",
      sortable: true,
      width: "80px",
    },
    {
      field: "bnb",
      name: "BNB",
      sortable: true,
      width: "80px",
    },
    {
      field: "doge",
      name: "DOGE",
      sortable: true,
      width: "60px",
    },
    {
      field: "gaia",
      name: "GAIA",
      sortable: true,
      width: "80px",
    },
    {
      field: "avax",
      name: "AVAX",
      sortable: true,
      width: "80px",
    },
  ];

  const search: EuiSearchBarProps = {
    query: currentSearch,
    onChange: (query: EuiSearchBarOnChangeArgs) => {
      params.set("search", query.queryText);
      history.push(`/nodes?${params.toString()}`);
    },
    box: {
      incremental: true,
    },
    filters: [
      {
        type: "field_value_selection",
        field: "status",
        name: "Status",
        multiSelect: "or",
        options: Object.values(
          nodes.reduce((acc: any, node: any) => {
            acc[node.status] = {
              value: node.status,
              name: node.status,
              view: node.status,
            };
            return acc;
          }, {}),
        ),
      },
      {
        type: "field_value_selection",
        field: "version",
        name: "Version",
        multiSelect: "or",
        options: Object.values(
          nodes.reduce((acc: any, node: any) => {
            acc[node.version] = {
              value: node.version,
              name: node.version,
              view: node.version,
            };
            return acc;
          }, {}),
        ),
      },
    ],
  };

  return (
    <div>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiPanel>
            <EuiFlexGroup alignItems="center" gutterSize="s">
              <EuiFlexItem>
                <EuiComboBox
                  fullWidth={true}
                  options={columns.map((c: any) => ({
                    label: c.name,
                  }))}
                  selectedOptions={filterColumns.map((c: string) => ({
                    label: c,
                  }))}
                  onChange={(columns: any) =>
                    setFilterColumnsHistory(columns.map((c: any) => c.label))
                  }
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="s" />
            <EuiInMemoryTable
              tableCaption="Nodes"
              items={nodes}
              columns={[columns[0]].concat(
                columns.filter((c) => filterColumns.includes(c.name)),
              )}
              search={search}
              pagination={false}
              sorting={true}
            />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      {nodeModal}
    </div>
  );
}
