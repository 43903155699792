import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  formatDate,
  EuiDualRange,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiButtonIcon,
  EuiLink,
  EuiInMemoryTable,
  EuiSearchBarProps,
  EuiSearchBarOnChangeArgs,
  EuiTabbedContent,
  EuiCode,
  EuiCodeBlock,
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiTitle,
} from "@elastic/eui";

import { APIEndpoint, ThornodeEndpoint, MidgardEndpoint } from "../config";

export default function Monitor() {
  // set state from url params
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const setTabsHistory = (tab: any) => {
    params.set("tab", tab.id);
    history.push(`/monitor?${params.toString()}`);
  };
  const currentTab = params.get("tab");
  const currentSearch = params.get("search") || "";

  // ------------------------------ shared ------------------------------

  const timeHeightColumns: Array<any> = [
    {
      field: "time",
      name: "Time",
      width: "120px",
      sortable: true,
      render: (time: any) => formatDate(time, "h:mm:ss a"),
    },
    {
      field: "height",
      name: "Height",
      width: "100px",
      render: (height: any) => (
        <EuiLink href={`https://runescan.io/block/${height}`}>
          <EuiCode transparentBackground language="json">
            {height}
          </EuiCode>
        </EuiLink>
      ),
    },
  ];

  const defaultPagination: any = {
    pageSize: 15,
    showPerPageOptions: false,
  };

  // ------------------------------ midgard pools ------------------------------

  const [pools, setPools] = useState<any>(false);

  useEffect(() => {
    const run = async () => {
      const poolsRes = await axios.get(`${MidgardEndpoint}/v2/pools`);
      let pools: any = {};
      poolsRes.data.forEach((pool: any) => {
        pools[pool.asset] = pool;
      });

      setPools(pools);
    };
    run();
  }, []);

  // ------------------------------ vaults ------------------------------

  const [asgardPubkeys, setAsgardPubkeys] = useState<any>(null);

  useEffect(() => {
    const run = async () => {
      const asgardRes = await axios.get(
        `${ThornodeEndpoint}/thorchain/vaults/asgard`,
      );
      setAsgardPubkeys(asgardRes.data.map((vault: any) => vault.pub_key));
    };
    run();
  }, []);

  // ------------------------------ outbound modal ------------------------------

  const [pendingOutbounds, setPendingOutbounds] = useState<any>([]);
  const [outboundModalIdx, setOutboundModalIdx] = useState(-1);
  const [outboundModal, setOutboundModal] = useState<any>([]);

  // scheduled outbounds are set later by tx events, but referenced in shared columns
  const [scheduledOutbounds, setScheduledOutbounds] = useState<any>([]);

  useEffect(() => {
    const run = async () => {
      if (outboundModalIdx < 0) {
        setOutboundModal([]);
        return;
      }

      const in_hash = pendingOutbounds[outboundModalIdx].in_hash;
      const closeModal = () => setOutboundModalIdx(-1);

      setOutboundModal(
        <EuiModal maxWidth={1200} onClose={closeModal}>
          <EuiSpacer size="s" />
          <EuiModalBody>
            <div>
              <EuiTitle>
                <h3>Outbound</h3>
              </EuiTitle>
              <EuiSpacer size="s" />
              <EuiCodeBlock
                isCopyable={true}
                whiteSpace="pre"
                paddingSize={"s"}
                language="json"
              >
                {JSON.stringify(pendingOutbounds[outboundModalIdx], null, 2)}
              </EuiCodeBlock>
            </div>
            {in_hash !==
              "0000000000000000000000000000000000000000000000000000000000000000" && (
              <div>
                <EuiSpacer />
                <EuiTitle>
                  <h3>Inbound</h3>
                </EuiTitle>
                <EuiSpacer size="s" />
                <EuiCodeBlock
                  isCopyable={true}
                  whiteSpace="pre"
                  paddingSize={"s"}
                  language="json"
                >
                  {JSON.stringify(
                    (
                      await axios.get(
                        `${ThornodeEndpoint}/thorchain/tx/${in_hash}`,
                      )
                    ).data,
                    null,
                    2,
                  )}
                </EuiCodeBlock>
              </div>
            )}
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>,
      );
    };
    run();
  }, [outboundModalIdx, pendingOutbounds]);

  // ------------------------------ outbounds ------------------------------

  useEffect(() => {
    const run = async () => {
      // only proceed when the midgard pools are fetched
      if (!pools) {
        return;
      }

      const outboundsRes = await axios.get(
        `${ThornodeEndpoint}/thorchain/queue/outbound`,
      );

      setPendingOutbounds(
        outboundsRes.data?.map((out: any, i: number) => {
          out.vault_type = asgardPubkeys.includes(out.vault_pub_key)
            ? "asgard"
            : "yggdrasil";
          out.idx = i;
          out.usd_value = Math.round(
            (parseInt(out.coin.amount) * pools[out.coin.asset]?.assetPriceUSD) /
              1e8,
          );
          return out;
        }) || [],
      );
    };
    run();
  }, [pools, asgardPubkeys]);

  const outboundsColumns = [
    {
      field: "idx",
      name: "",
      width: "60px",
      render: (idx: number) => (
        <EuiButtonIcon
          iconType="search"
          onClick={() => setOutboundModalIdx(idx)}
        />
      ),
    },
    {
      field: "chain",
      name: "Chain",
      width: "80px",
      sortable: true,
    },
    {
      field: "memo",
      name: "Type",
      width: "120px",
      sortable: true,
      render: (memo: any) => <EuiCode>{memo.split(":")[0]}</EuiCode>,
    },
    {
      field: "usd_value",
      name: "Amount (USD)",
      width: "200px",
      render: (usd_value: number) =>
        isNaN(usd_value)
          ? ""
          : `$${usd_value?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
      footer: (rows: any) =>
        `$${rows.items
          .reduce((acc: any, row: any) => {
            return acc + row.usd_value;
          }, 0)
          .toLocaleString("en-US")}`,
      sortable: true,
    },
    {
      field: "coin",
      name: "Amount",
      width: "200px",
      render: (coin: any) => {
        let decimals = coin.decimals;
        if (!decimals || decimals === "0") decimals = 8;
        const base = Math.pow(10, parseInt(decimals));
        return (parseInt(coin.amount) / base).toLocaleString("en-US", {
          maximumFractionDigits: 8,
        });
      },
    },
    {
      field: "coin",
      name: "Asset",
      width: "120px",
      sortable: true,
      render: (coin: any) => coin.asset.split("-")[0],
    },
    {
      field: "max_gas",
      name: "Max Gas",
      width: "200px",
      render: (maxGases: any) =>
        maxGases.map(
          (maxGas: any) =>
            `${maxGas.amount / Math.pow(10, maxGas.decimals)} ${maxGas.asset}`,
        ),
      truncateText: true,
    },
    {
      field: "vault_pub_key",
      name: "Vault",
      width: "60px",
      sortable: true,
      render: (vault: any) => (
        <EuiCode>{vault.substring(vault.length - 4, vault.length)}</EuiCode>
      ),
      truncateText: true,
    },
    {
      field: "vault_type",
      name: "Vault Type",
      width: "90px",
      sortable: true,
      render: (type: any) => <EuiCode>{type}</EuiCode>,
      truncateText: true,
    },
    {
      field: "in_hash",
      name: "",
      render: (in_hash: any) =>
        in_hash !==
          "0000000000000000000000000000000000000000000000000000000000000000" && (
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiFlexItem grow={false}>
              <EuiButton size="s" href={`https://runescan.io/tx/${in_hash}`}>
                ViewBlock Inbound
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
      truncateText: true,
    },
  ];

  const allOutbounds = pendingOutbounds.concat(scheduledOutbounds);

  const outboundsSearch: EuiSearchBarProps = {
    query: currentSearch,
    onChange: (query: EuiSearchBarOnChangeArgs) => {
      params.set("search", query.queryText);
      history.push(`/monitor?${params.toString()}`);
    },
    filters: [
      {
        type: "field_value_selection",
        field: "chain",
        name: "Chain",
        multiSelect: "or",
        options: Object.values(
          allOutbounds.reduce((acc: any, out: any) => {
            acc[out.chain] = {
              value: out.chain,
              name: out.chain,
            };
            return acc;
          }, {}),
        ),
      },
      {
        type: "field_value_selection",
        field: "memo",
        name: "Type",
        multiSelect: "or",
        filterWith: "prefix",
        options: Object.values(
          allOutbounds.reduce((acc: any, out: any) => {
            acc[out.memo.split(":")[0]] = {
              value: out.memo.split(":")[0].split("+")[0], // remove + for ygg fund
              name: out.memo.split(":")[0],
              view: out.memo.split(":")[0],
            };
            return acc;
          }, {}),
        ),
      },
      {
        type: "field_value_selection",
        field: "coin.asset",
        name: "Asset",
        multiSelect: "or",
        filterWith: "prefix",
        options: Object.values(
          allOutbounds.reduce((acc: any, out: any) => {
            acc[out.coin.asset.split("-")[0]] = {
              value: out.coin.asset.split("-")[0],
              name: out.coin.asset.split("-")[0],
            };
            return acc;
          }, {}),
        ),
      },
      {
        type: "field_value_selection",
        field: "vault_pub_key",
        name: "Vault",
        multiSelect: "or",
        options: Object.values(
          allOutbounds.reduce((acc: any, out: any) => {
            acc[out.vault_pub_key] = {
              value: out.vault_pub_key,
              name: out.vault_pub_key,
              view: out.vault_pub_key.substring(
                out.vault_pub_key.length - 4,
                out.vault_pub_key.length,
              ),
            };
            return acc;
          }, {}),
        ),
      },
    ],
  };

  // ------------------------------ logs ------------------------------

  const [logs, setLogs] = useState<Array<string>>([]);

  useEffect(() => {
    axios.get(`${APIEndpoint}/thorchain/logs`).then((response) => {
      let lines: Array<any> = [];

      response.data
        .split("\n")
        .reverse()
        .forEach((line: string) => {
          try {
            const log: any = { fields: JSON.parse(line) };

            // extract columns from fields
            log.time = Date.parse(log.fields.time);
            log.level = log.fields.level;
            delete log.fields.time;
            delete log.fields.level;
            log.fields = JSON.stringify(log.fields, null, 2);

            lines.push(log);
          } catch {}
        });
      setLogs(lines);
    });
  }, []);

  const logsColumns = [
    {
      field: "time",
      name: "Time",
      width: "120px",
      sortable: true,
      render: (time: any) => formatDate(time, "h:mm:ss a"),
    },
    {
      field: "level",
      name: "Level",
      width: "100px",
      render: (level: string) => (
        <EuiCode transparentBackground language="json">
          {level}
        </EuiCode>
      ),
      sortable: true,
    },
    {
      field: "fields",
      name: "Fields",
      render: (fields: any) => (
        <EuiCodeBlock
          paddingSize={"none"}
          transparentBackground
          language="json"
        >
          {fields}
        </EuiCodeBlock>
      ),
    },
  ];

  const logsSearch: any = {
    query: currentSearch,
    onChange: (query: EuiSearchBarOnChangeArgs) => {
      params.set("search", query.queryText);
      history.push(`/monitor?${params.toString()}`);
    },
    filters: [
      {
        type: "field_value_selection",
        field: "level",
        name: "Level",
        multiSelect: "or",
        options: Object.values(
          logs.reduce((acc: any, log: any) => {
            acc[log.level] = {
              value: log.level,
              name: log.level,
              view: log.level,
            };
            return acc;
          }, {}),
        ),
      },
    ],
  };

  // ------------------------------ events ------------------------------

  const [txEvents, setTxEvents] = useState<Array<any>>([]);
  const [blockEvents, setBlockEvents] = useState<Array<any>>([]);

  useEffect(() => {
    const run = async () => {
      // only proceed when the midgard pools are fetched
      if (!pools) {
        return;
      }

      const [txEventsRes, blockEventsRes] = await Promise.all([
        axios.get(`${APIEndpoint}/thorchain/events/tx`),
        axios.get(`${APIEndpoint}/thorchain/events/block`),
      ]);

      let scheduledOutbounds: any = [];
      [txEventsRes, blockEventsRes].forEach((res) => {
        scheduledOutbounds.push(
          ...res.data
            .filter((event: any) => event.type === "scheduled_outbound")
            .map((event: any) => ({
              ...event,
              coin: {
                amount: event.coin_amount,
                asset: event.coin_asset,
                decimals: event.coin_decimals,
              },
              max_gas: [
                {
                  amount: event.max_gas_amount_0,
                  asset: event.max_gas_asset_0,
                  decimals: event.max_gas_decimals_0,
                },
              ],
              vault_type: asgardPubkeys.includes(event.vault_pub_key)
                ? "asgard"
                : "yggdrasil",
              usd_value: Math.round(
                (parseInt(event.coin_amount) *
                  pools[event.coin_asset]?.assetPriceUSD) /
                  1e8,
              ),
            })),
        );
      });
      scheduledOutbounds.sort((a: any, b: any) => (a.time < b.time ? 1 : -1));
      setScheduledOutbounds(scheduledOutbounds);

      setTxEvents(
        txEventsRes.data.reverse().map((event: any) => {
          const e: any = {
            height: event.height,
            tx: event.tx,
            type: event.type,
            time: Date.parse(event.time),
          };
          delete event.height;
          delete event.type;
          delete event.time;
          e.event = JSON.stringify(event, null, " ");
          return e;
        }),
      );

      setBlockEvents(
        blockEventsRes.data.reverse().map((event: any) => {
          const e: any = {
            height: event.height,
            type: event.type,
            time: Date.parse(event.time),
          };
          delete event.height;
          delete event.type;
          delete event.time;
          e.event = JSON.stringify(event, null, " ");
          return e;
        }),
      );
    };
    run();
  }, [pools, asgardPubkeys]);

  const blockEventsColumns = timeHeightColumns.concat([
    {
      field: "type",
      name: "Type",
      width: "200px",
      render: (type: any) => (
        <EuiCode transparentBackground language="json">
          {type}
        </EuiCode>
      ),
    },
    {
      field: "event",
      name: "Event",
      render: (event: any) => (
        <EuiCodeBlock
          paddingSize={"none"}
          transparentBackground
          language="json"
        >
          {event}
        </EuiCodeBlock>
      ),
    },
  ]);

  const txEventsColumns = blockEventsColumns.concat([
    {
      field: "tx",
      name: "",
      width: "160px",
      render: (txid: any) => (
        <EuiFlexGroup gutterSize="s" direction="column">
          <EuiFlexItem grow={false}>
            <EuiButton
              size="s"
              color="success"
              href={`https://runescan.io/tx/${txid}`}
            >
              ViewBlock Tx
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              size="s"
              href={`https://thornode.ninerealms.com/cosmos/tx/v1beta1/txs/${txid}`}
            >
              Thornode Tx
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
  ]);

  // ------------------------------ all ------------------------------

  const allColumns = timeHeightColumns.concat([
    {
      field: "",
      name: "",
      width: "200px",
      render: (row: any) => (
        <EuiCode transparentBackground language="json">
          {(row.type && `[EVENT] ${row.type}`) || `[LOG] ${row.level}`}
        </EuiCode>
      ),
    },
    {
      field: "",
      name: "Data",
      render: (row: any) => (
        <EuiCodeBlock
          paddingSize={"none"}
          transparentBackground
          language="json"
        >
          {row.fields || row.event}
        </EuiCodeBlock>
      ),
    },
  ]);

  let all: Array<any> = [];
  if (logs && blockEvents && txEvents) {
    all = logs.concat(blockEvents).concat(txEvents);
    all.sort((a: any, b: any) => (a.time < b.time ? 1 : -1));
  }

  // ------------------------------ height filter ------------------------------

  // get min height from all
  const minHeight = all.reduce((acc: any, row: any) => {
    if (!row.height) {
      return acc;
    }
    if (acc === 0) {
      return row.height;
    }
    return Math.min(acc, row.height);
  }, 0);

  // get max height from all
  const maxHeight = all.reduce((acc: any, row: any) => {
    if (!row.height) {
      return acc;
    }
    if (acc === 0) {
      return row.height;
    }
    return Math.max(acc, row.height);
  }, 0);

  const [minMaxHeight, setMinMaxHeight] = useState<any>([minHeight, maxHeight]);

  const filterHeight = (row: any) => {
    const [minHeight, maxHeight] = minMaxHeight;
    if (!row.height) {
      return true;
    }
    if (minHeight > 0 && row.height < minHeight) {
      return false;
    }
    if (maxHeight > 0 && row.height > maxHeight) {
      return false;
    }
    return true;
  };

  const minMaxHeightRange = (
    <EuiDualRange
      value={minMaxHeight}
      onChange={setMinMaxHeight}
      min={minHeight}
      max={maxHeight}
      showInput
    />
  );

  // ------------------------------ search ------------------------------

  const txEventsSearch: any = {
    query: currentSearch,
    onChange: (query: EuiSearchBarOnChangeArgs) => {
      params.set("search", query.queryText);
      history.push(`/monitor?${params.toString()}`);
    },
    toolsLeft: minMaxHeightRange,
    filters: [
      {
        type: "field_value_selection",
        field: "type",
        name: "Type",
        multiSelect: "or",
        options: Object.values(
          txEvents.reduce((acc: any, event: any) => {
            acc[event.type] = {
              value: event.type,
              name: event.type,
              view: event.type,
            };
            return acc;
          }, {}),
        ),
      },
    ],
  };

  const blockEventsSearch: any = {
    query: currentSearch,
    onChange: (query: EuiSearchBarOnChangeArgs) => {
      params.set("search", query.queryText);
      history.push(`/monitor?${params.toString()}`);
    },
    toolsLeft: minMaxHeightRange,
    filters: [
      {
        type: "field_value_selection",
        field: "type",
        name: "Type",
        multiSelect: "or",
        options: Object.values(
          blockEvents.reduce((acc: any, event: any) => {
            acc[event.type] = {
              value: event.type,
              name: event.type,
              view: event.type,
            };
            return acc;
          }, {}),
        ),
      },
    ],
  };

  // ------------------------------ tabs ------------------------------

  const tabs = [
    {
      id: "pending-outbounds",
      name: "Pending Outbounds",
      content: (
        <div>
          <EuiSpacer />
          <EuiInMemoryTable
            tableCaption="Pending Outbounds"
            items={pendingOutbounds}
            columns={outboundsColumns}
            search={outboundsSearch}
            sorting={true}
            pagination={defaultPagination}
          />
        </div>
      ),
    },
    {
      id: "outbounds",
      name: "Outbounds",
      content: (
        <div>
          <EuiSpacer />
          <EuiInMemoryTable
            tableCaption="Outbounds"
            items={scheduledOutbounds}
            columns={timeHeightColumns.concat(outboundsColumns.slice(1))}
            search={outboundsSearch}
            sorting={true}
            pagination={{ ...defaultPagination, pageSize: 20 }}
          />
        </div>
      ),
    },
    {
      id: "tx-events",
      name: "Tx Events",
      content: (
        <div>
          <EuiSpacer />
          <EuiInMemoryTable
            tableCaption="Tx Events"
            items={txEvents.filter(filterHeight)}
            columns={txEventsColumns}
            search={txEventsSearch}
            sorting={true}
            pagination={defaultPagination}
          />
        </div>
      ),
    },
    {
      id: "block-events",
      name: "Block Events",
      content: (
        <div>
          <EuiSpacer />
          <EuiInMemoryTable
            tableCaption="Block Events"
            items={blockEvents.filter(filterHeight)}
            columns={blockEventsColumns}
            search={blockEventsSearch}
            sorting={true}
            pagination={defaultPagination}
          />
        </div>
      ),
    },
    {
      id: "logs",
      name: "Logs",
      content: (
        <div>
          <EuiSpacer />
          <EuiInMemoryTable
            tableCaption="Logs"
            columns={logsColumns}
            items={logs}
            search={logsSearch}
            sorting={true}
            pagination={defaultPagination}
          />
        </div>
      ),
    },
    {
      id: "all",
      name: "All",
      content: (
        <div>
          <EuiSpacer />
          <EuiInMemoryTable
            tableCaption="All"
            columns={allColumns}
            items={all.filter(filterHeight)}
            search={{
              toolsLeft: minMaxHeightRange,
            }}
            sorting={true}
            pagination={defaultPagination}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <EuiPanel>
        <EuiTabbedContent
          tabs={tabs}
          initialSelectedTab={
            tabs.filter((tab) => tab.id === currentTab)[0] || tabs[0]
          }
          onTabClick={setTabsHistory}
        />
      </EuiPanel>
      {outboundModal}
    </div>
  );
}
