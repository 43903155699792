import axios from "axios";
import { useEffect, useState } from "react";
import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiCode,
  EuiTitle,
  EuiHorizontalRule,
  EuiInMemoryTable,
  EuiSearchBarProps,
} from "@elastic/eui";
import {
  Chart,
  Axis,
  BarSeries,
  Position,
  ScaleType,
  Settings,
} from "@elastic/charts";

import { EUI_CHARTS_THEME_LIGHT } from "@elastic/eui/dist/eui_charts_theme";

import { ThornodeEndpoint } from "../config";

export default function Mimir() {
  // ------------------------------ mimirs ------------------------------

  const [mimirs, setMimirs] = useState<any>([]);
  useEffect(() => {
    const run = async () => {
      setMimirs((await axios.get(`${ThornodeEndpoint}/thorchain/mimir`)).data);
    };
    run();
  }, []);

  const mimirsColumns = [
    {
      field: "key",
      name: "Mimir",
      truncateText: true,
      render: (key: any) => (
        <EuiCode transparentBackground language="json">
          {key}
        </EuiCode>
      ),
      sortable: true,
    },
    {
      field: "value",
      name: "Value",
      sortable: true,
    },
  ];

  // ------------------------------ votes ------------------------------

  const [votes, setVotes] = useState([]);
  const [activeNodeCount, setActiveNodeCount] = useState(0);
  useEffect(() => {
    axios.get(`${ThornodeEndpoint}/thorchain/nodes`).then((response) => {
      const nodes = new Set(
        response.data
          .filter((node: any) => node.status === "Active")
          .map((node: any) => node.node_address),
      );
      setActiveNodeCount(nodes.size);
      axios
        .get(`${ThornodeEndpoint}/thorchain/mimir/nodes_all`)
        .then((response) => {
          setVotes(
            response.data.mimirs
              .filter((vote: any) => nodes.has(vote.signer))
              .map((vote: any) => ({ ...vote, value: vote.value || 0 })),
          );
        });
    });
  }, []);

  // count votes for chart data
  const chartVotes = votes.reduce((acc: any, vote: any) => {
    if (!(vote.key in acc)) {
      acc[vote.key] = {};
    }
    if (!(vote.value in acc[vote.key])) {
      acc[vote.key][vote.value] = 1;
    } else {
      acc[vote.key][vote.value] += 1;
    }
    return acc;
  }, {});

  // transform votes to rows for chart
  let chartData: any = [];
  Object.keys(chartVotes).forEach((key) => {
    Object.keys(chartVotes[key]).forEach((value) => {
      chartData.push({
        x: `${key} (Current: ${mimirs[key]})`,
        g: value,
        y: chartVotes[key][value],
      });
    });
  });

  let voteTotals: any = [];
  Object.keys(chartVotes).forEach((key) => {
    const values: Array<number> = Object.values(chartVotes[key]);
    const max: any = Math.max(...values);

    voteTotals.push({
      key: key,
      current: mimirs[key],
      topVote: Object.keys(chartVotes[key]).filter(
        (vote: any) => chartVotes[key][vote] === max,
      ),
      voteCount: max,
      consensus: `${((max / activeNodeCount) * 100).toFixed(2)}%`,
    });
  });

  const votesColumns = [
    {
      field: "signer",
      name: "Node",
      width: "80px",
      render: (na: string) => {
        return `${na.substring(na.length - 4, na.length)}`;
      },
      sortable: true,
    },
    {
      field: "key",
      name: "Mimir",
      truncateText: true,
      render: (key: any) => (
        <EuiCode transparentBackground language="json">
          {key}
        </EuiCode>
      ),
      sortable: true,
    },
    {
      field: "value",
      name: "Vote",
      width: "120px",
      sortable: true,
    },
  ];

  const voteTotalsColumns = [
    {
      field: "key",
      name: "Mimir",
      truncateText: true,
      render: (key: any) => (
        <EuiCode transparentBackground language="json">
          {key}
        </EuiCode>
      ),
      sortable: true,
    },
    {
      field: "current",
      name: "Current",
      width: "120px",
      sortable: true,
    },
    {
      field: "topVote",
      name: "Top Vote",
      width: "120px",
      sortable: true,
    },
    {
      field: "voteCount",
      name: "Count",
      width: "120px",
      sortable: true,
    },
    {
      field: "consensus",
      name: "Consensus",
      width: "120px",
      sortable: true,
    },
  ];

  const votesSearch: EuiSearchBarProps = {
    box: {
      incremental: true,
    },
    filters: [
      {
        type: "field_value_selection",
        field: "key",
        name: "Mimir",
        multiSelect: false,
        options: Object.values(
          votes.reduce((acc: any, vote: any) => {
            acc[vote.key] = { value: vote.key, name: vote.key, view: vote.key };
            return acc;
          }, {}),
        ),
      },
    ],
  };

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={3}>
        <EuiPanel style={{ maxHeight: "calc(100vh - 80px)" }} paddingSize="m">
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              <EuiTitle>
                <h2>Vote Results</h2>
              </EuiTitle>
              <div style={{ height: "40vh" }}>
                <Chart>
                  <Settings rotation={90} theme={EUI_CHARTS_THEME_LIGHT} />
                  <Axis
                    id="bottom"
                    position={Position.Bottom}
                    title="Vote Count"
                    showOverlappingTicks
                  />
                  <Axis
                    id="left"
                    position={Position.Left}
                    showOverlappingTicks
                  />
                  <BarSeries
                    xScaleType={ScaleType.Linear}
                    yScaleType={ScaleType.Linear}
                    xAccessor="x"
                    yAccessors={["y"]}
                    splitSeriesAccessors={["g"]}
                    stackAccessors={["g"]}
                    id="votes"
                    name="Votes"
                    data={chartData}
                  />
                </Chart>
              </div>
            </EuiFlexItem>
            <EuiHorizontalRule />
            <EuiFlexItem style={{ maxHeight: "calc(55vh - 200px)" }}>
              <EuiFlexGroup className="eui-yScroll">
                <EuiFlexItem>
                  <EuiInMemoryTable
                    tableCaption="Votes"
                    items={voteTotals}
                    columns={voteTotalsColumns}
                    sorting={true}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem grow={2}>
        <EuiPanel paddingSize="m" style={{ maxHeight: "calc(50vh - 55px)" }}>
          <EuiFlexGroup className="eui-yScroll">
            <EuiFlexItem>
              <EuiTitle>
                <h2>Active Configuration</h2>
              </EuiTitle>
              <EuiSpacer />
              <EuiInMemoryTable
                tableCaption="Mimirs"
                items={Object.entries(mimirs).map(([k, v]: [any, any]) => ({
                  key: k,
                  value: v,
                }))}
                columns={mimirsColumns}
                search={{ box: { incremental: true } }}
                sorting={true}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel paddingSize="m" style={{ maxHeight: "calc(50vh - 55px)" }}>
          <EuiFlexGroup className="eui-yScroll">
            <EuiFlexItem>
              <EuiTitle>
                <h2>Node Votes</h2>
              </EuiTitle>
              <EuiSpacer />
              <EuiInMemoryTable
                tableCaption="Votes"
                items={votes}
                columns={votesColumns}
                search={votesSearch}
                sorting={true}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
