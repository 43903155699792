import axios from "axios";
import { useEffect, useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTitle,
  EuiInMemoryTable,
  EuiStat,
  EuiSpacer,
} from "@elastic/eui";

import { APIEndpoint, RPCEndpoint, MidgardEndpoint } from "../config";

import moment from "moment";

export default function Network() {
  // ------------------------------ status ------------------------------

  const [status, setStatus] = useState<any>(false);
  useEffect(() => {
    const run = async () => {
      setStatus((await axios.get(`${RPCEndpoint}/status`)).data);
    };
    run();
  }, []);

  // ------------------------------ network ------------------------------

  const [network, setNetwork] = useState<any>(false);
  useEffect(() => {
    const run = async () => {
      setNetwork((await axios.get(`${MidgardEndpoint}/v2/network`)).data);
    };
    run();
  }, []);

  // ------------------------------ supply ------------------------------

  const [supply, setSupply] = useState<any>([]);

  useEffect(() => {
    const run = async () => {
      const supply = await axios.get(`${APIEndpoint}/thorchain/supply`);
      setSupply(
        Object.entries(supply.data).map(([k, v]: [any, any]) => ({
          chain: k,
          circulating: v.circulating,
          locked:
            v.locked &&
            Object.values(v.locked).reduce((acc: any, x: any) => {
              return acc + x;
            }, 0),
          total: v.total,
        })),
      );
    };
    run();
  }, []);

  const supplyColumns = [
    {
      field: "chain",
      name: "Chain",
      sortable: true,
      width: "60px",
    },
    {
      field: "circulating",
      name: "Circulating",
      render: (circulating: string) =>
        `ᚱ${parseInt(circulating).toLocaleString("en-US")}`,
      sortable: true,
      width: "60px",
    },
    {
      field: "locked",
      name: "Locked",
      render: (locked: string) =>
        `ᚱ${parseInt(locked).toLocaleString("en-US")}`,
      sortable: true,
      width: "60px",
    },
    {
      field: "total",
      name: "Total",
      render: (total: string) => `ᚱ${parseInt(total).toLocaleString("en-US")}`,
      sortable: true,
      width: "60px",
    },
  ];

  // ------------------------------ churn calc ------------------------------

  let churnBlocks, churnDate, churnDuration, churnColor;
  if (network && status) {
    churnBlocks =
      network.nextChurnHeight -
      parseInt(status.result.sync_info.latest_block_height);
    const churnSeconds = 6 * churnBlocks;
    churnDate = moment().add(churnSeconds, "seconds");
    churnDuration = `${Math.floor(churnSeconds / 86400)}d ${Math.floor(
      (churnSeconds / 3600) % 24,
    )}h ${Math.floor((churnSeconds / 60) % 60)}m`;

    // danger when churn within 1k blocks
    churnColor =
      network.nextChurnHeight -
        parseInt(status.result.sync_info.latest_block_height) >
      1000
        ? "subdued"
        : "danger";
  }

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              {network && status && (
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiStat
                      title={status.result.sync_info.latest_block_height}
                      titleSize="m"
                      description="Current Height"
                    />
                    <EuiStat
                      title={network.activeNodeCount}
                      titleSize="m"
                      description="Active Nodes"
                    />
                    <EuiStat
                      title={network.standbyNodeCount}
                      titleSize="m"
                      titleColor="subdued"
                      description="Standby Nodes"
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={2}>
                    <EuiStat
                      title={network.nextChurnHeight}
                      textAlign="right"
                      titleSize="m"
                      titleColor={
                        network.nextChurnHeight -
                          parseInt(
                            status.result.sync_info.latest_block_height,
                          ) >
                        1000
                          ? "subdued"
                          : "danger"
                      }
                      description="Churn Height"
                    />
                    <EuiStat
                      title={churnDate?.format("lll")}
                      textAlign="right"
                      titleSize="m"
                      titleColor={churnColor}
                      description="Churn Time (UTC)"
                    />
                    <EuiStat
                      title={churnDuration}
                      textAlign="right"
                      titleSize="m"
                      titleColor={churnColor}
                      description="Time Until Churn"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel>
              {network && (
                <EuiFlexGroup justifyContent="center">
                  <EuiFlexItem>
                    <EuiStat
                      title={(network.bondingAPY * 100).toFixed(2) + "%"}
                      titleSize="m"
                      description="Bonding APY"
                      titleColor="success"
                    />
                    <EuiStat
                      title={(network.liquidityAPY * 100).toFixed(2) + "%"}
                      titleSize="m"
                      description="Liquidity APY"
                      titleColor="success"
                    />
                  </EuiFlexItem>
                  {network && (
                    <EuiFlexItem>
                      <EuiStat
                        title={`ᚱ${Math.round(
                          parseInt(network.bondMetrics.totalActiveBond) / 1e8,
                        ).toLocaleString("en-US")}`}
                        textAlign="right"
                        titleSize="m"
                        description="Bonded Rune"
                      />
                      <EuiStat
                        title={`ᚱ${Math.round(
                          parseInt(network.totalPooledRune) / 1e8,
                        ).toLocaleString("en-US")}`}
                        textAlign="right"
                        titleSize="m"
                        description="Pooled Rune"
                      />
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              )}
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              <EuiTitle>
                <h2>Supply</h2>
              </EuiTitle>
              <EuiSpacer />
              <EuiInMemoryTable
                tableCaption="Supply"
                items={supply}
                columns={supplyColumns}
                pagination={false}
                sorting={true}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
