import {
  EuiButtonGroup,
  EuiButtonGroupOptionProps,
  EuiDatePicker,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiTabbedContent,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui";
import axios from "axios";
import moment from "moment-timezone";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  GenesisUnixTimestamp,
  MidgardEndpoint,
  PoolModule,
  ThornodeV1Endpoint,
} from "../config";
import { getBTCComHeight, getDefiLlamaHeight } from "../utils/requests";

export default function Tools() {
  // set state from url params
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const setTabsHistory = (tab: any) => {
    params.set("tab", tab.id);
    history.push(`/tools?${params.toString()}`);
  };
  const currentTab = params.get("tab");

  // ----------------------------- chains -----------------------------

  // Set initial timestamp to a 30 second delay to avoid MidgardAPI 400 error.
  const [unixTimestamp, setUnixTimestamp] = useState<number>(
    moment.utc().subtract(30, "seconds").unix(),
  );

  const [chainHeights, setChainHeights] = useState<any[]>([]);
  const [unixOutOfRangeAlert, setUnixOutOfRangeAlert] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Format Local v. UTC selection toggle buttons.
  const useLocalTimezoneToggleButtonGroupPrefix = useGeneratedHtmlId({
    prefix: "useLocalTimezoneToggleButtonGroup",
  });
  const localTimezoneToggleButtons: EuiButtonGroupOptionProps[] = [
    {
      id: `${useLocalTimezoneToggleButtonGroupPrefix}__0`,
      label: "UTC",
      iconType: "globe",
    },
    {
      id: `${useLocalTimezoneToggleButtonGroupPrefix}__1`,
      label: "Local",
      iconType: "mapMarker",
    },
  ];
  const [useLocalTimezone, setUseLocalTimezone] = useState<boolean>(false);
  const [useLocalTimezoneId, setUseLocalTimezoneId] = useState<string>(
    `${useLocalTimezoneToggleButtonGroupPrefix}__0`,
  );

  // Change the moment formatting for the datepicker based on
  // whether we use local timezone or not.
  if (useLocalTimezone) {
    moment.tz.setDefault();
  } else {
    moment.tz.setDefault("UTC");
  }

  const toggleLocalTimezoneButtons = (id: string) => {
    setIsLoading(true);
    setUseLocalTimezone(!useLocalTimezone);
    setUseLocalTimezoneId(id);
  };

  // Format Third-Party API selection toggle buttons.
  const useThirdPartyToggleButtonGroupPrefix = useGeneratedHtmlId({
    prefix: "useThirdPartyToggleButtonGroup",
  });
  const thirdPartyToggleButtons: EuiButtonGroupOptionProps[] = [
    {
      id: `${useThirdPartyToggleButtonGroupPrefix}__0`,
      label: "THORNode Only",
      iconType: "bolt",
    },
    {
      id: `${useThirdPartyToggleButtonGroupPrefix}__1`,
      label: "Third Party APIs",
      iconType: "globe",
    },
  ];
  const [useThirdParty, setUseThirdParty] = useState<boolean>(false);
  const [useThirdPartyId, setUseThirdPartyId] = useState<string>(
    `${useThirdPartyToggleButtonGroupPrefix}__0`,
  );

  const toggleThirdPartyButtons = (id: string) => {
    setIsLoading(true);
    setUseThirdParty(!useThirdParty);
    setUseThirdPartyId(id);
  };

  const onUnixTimestampChangeHandler = (newUnixTimestamp: number) => {
    setIsLoading(true);
    setUnixTimestamp(newUnixTimestamp);
  };

  useEffect(() => {
    const getChainHeights = async () => {
      const chainHeights: ChainHeight[] = [];
      const midgardConfig = {
        params: {
          timestamp: unixTimestamp,
        },
      };
      const midgardRes = await axios
        .get(`${MidgardEndpoint}/v2/balance/${PoolModule}`, midgardConfig)
        .catch(function (error) {
          if (error.response) {
            setUnixOutOfRangeAlert(true);
            setTimeout(() => setUnixOutOfRangeAlert(false), 2000);
          }
        });
      if (!midgardRes) return setChainHeights(chainHeights);

      const thorHeight = Number(midgardRes.data.height);
      chainHeights.push({
        chain: "THOR",
        height: thorHeight,
        last_observed_height: thorHeight,
        delta_height: 0,
        source: "Midgard",
      });
      const thornodeConfig = {
        params: {
          height: thorHeight,
        },
      };
      const thornodeRes = await axios.get(
        `${ThornodeV1Endpoint}/thorchain/lastblock`,
        thornodeConfig,
      );

      if (!thornodeRes) return setChainHeights(chainHeights);
      chainHeights.push(
        ...(thornodeRes.data ?? []).map(
          (d: Record<string, string | number>): ChainHeight => ({
            chain: d.chain as string,
            height: d.last_observed_in as number,
            last_observed_height: d.last_observed_in as number,
            delta_height: 0,
            source: "THORNode",
          }),
        ),
      );

      if (useThirdParty) {
        // Logic to use third-party APIs.
        for (let i = 0; i < chainHeights.length; i += 1) {
          const { chain, last_observed_height: thornodeHeight } =
            chainHeights[i];
          if (chain === "BTC") {
            const newChainHeight = await getBTCComHeight(
              chain,
              unixTimestamp,
              thornodeHeight,
            );
            if (newChainHeight) chainHeights[i] = newChainHeight;
          }
          if (["AVAX", "ETH"].includes(chain)) {
            const newChainHeight = await getDefiLlamaHeight(
              chain,
              unixTimestamp,
              thornodeHeight,
            );
            if (newChainHeight) chainHeights[i] = newChainHeight;
          }
        }
      }
      setChainHeights(
        chainHeights.sort((a, b) => a.chain.localeCompare(b.chain)),
      );
      setIsLoading(false);
    };

    getChainHeights();
  }, [unixTimestamp, useThirdParty, useLocalTimezone]);

  const chainHeightsColumnsTHORChainOnly = [
    {
      field: "chain",
      name: "Chain",
      width: "100px",
      render: (chain: string) => chain,
    },
    {
      field: "last_observed_height",
      name: "Last Observed Height (THORChain)",
      width: "100px",
      render: (last_observed_height: number) => last_observed_height,
    },
    {
      field: "source",
      name: "Source",
      width: "100px",
      render: (source: string) => source,
    },
  ];

  const chainHeightsColumnsWithThirdParties = [
    {
      field: "chain",
      name: "Chain",
      width: "100px",
      render: (chain: string) => chain,
    },
    {
      field: "last_observed_height",
      name: "Last Observed Height (THORChain)",
      width: "100px",
      render: (last_observed_height: number) => last_observed_height,
    },
    {
      field: "height",
      name: "Nearest Height (Third-Party or THORChain)",
      width: "100px",
      render: (height: number) => height,
    },
    {
      field: "delta_height",
      name: "Nearest - Last Observed",
      width: "100px",
    },
    {
      field: "source",
      name: "Source",
      width: "100px",
      render: (source: string) => source,
    },
  ];

  const getChainHeightsColumns = () =>
    useThirdParty
      ? chainHeightsColumnsWithThirdParties
      : chainHeightsColumnsTHORChainOnly;

  // ------------------------------ tabs ------------------------------

  const tabs = [
    {
      id: "chain-heights",
      name: "Chain Heights",
      content: (
        <div>
          <EuiText>
            <EuiSpacer />
            <p>
              The Chain Heights tool uses various blockchain APIs to attempt to
              find the nearest block for all THORChain-supported chains, given a
              specific timestamp.
            </p>
            <span>
              <strong>THORChain Only:</strong> Uses only THORNode and Midgard to
              provide the last Bifrost-observed L1 block.
            </span>
            <br />
            <span>
              <strong>Third Party APIs:</strong> Uses a combination of THORNode,
              Midgard, and third-party L1 chain APIs to provide the nearest
              block.
            </span>
          </EuiText>
          <EuiSpacer />
          <EuiFlexGroup style={{ alignItems: "center" }}>
            <EuiFlexItem grow={false}>
              <EuiDatePicker
                selected={moment.unix(unixTimestamp)}
                showTimeSelect
                onChange={(m: moment.Moment) =>
                  onUnixTimestampChangeHandler(m?.unix())
                }
                dateFormat={
                  useLocalTimezone
                    ? "YYYY-MM-DD HH:mm:ss zz"
                    : "YYYY-MM-DD HH:mm:ss [UTC]"
                }
                timeFormat="HH:mm:ss"
                minDate={moment.unix(GenesisUnixTimestamp)}
                maxDate={moment()}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonGroup
                color={useLocalTimezone ? "danger" : "success"}
                name="local-or-utc"
                legend="Display local v. UTC timestamps"
                idSelected={useLocalTimezoneId}
                options={localTimezoneToggleButtons}
                onChange={toggleLocalTimezoneButtons}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFieldNumber
                prepend="Unix Timestamp"
                value={unixTimestamp}
                onChange={(e) =>
                  onUnixTimestampChangeHandler(Number(e.target.value))
                }
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonGroup
                color={useThirdParty ? "success" : "warning"}
                name="third-party-apis"
                legend="THORChain only or with Third-Party APIs"
                idSelected={useThirdPartyId}
                options={thirdPartyToggleButtons}
                onChange={toggleThirdPartyButtons}
              />
            </EuiFlexItem>
            {isLoading && <EuiLoadingSpinner size="l" />}
            {unixOutOfRangeAlert && (
              <EuiFlexItem grow={false}>Timestamp is out of range.</EuiFlexItem>
            )}
          </EuiFlexGroup>
          <EuiSpacer />
          {isLoading ? (
            <EuiInMemoryTable
              tableCaption="Chain Heights"
              items={[]}
              columns={[]}
              sorting={true}
            />
          ) : (
            <EuiInMemoryTable
              tableCaption="Chain Heights"
              items={chainHeights}
              columns={getChainHeightsColumns()}
              sorting={true}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <EuiPanel>
        <EuiTabbedContent
          tabs={tabs}
          initialSelectedTab={
            tabs.filter((tab) => tab.id === currentTab)[0] || tabs[0]
          }
          onTabClick={setTabsHistory}
        />
      </EuiPanel>
    </div>
  );
}
