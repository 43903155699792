import {
  EuiHeader,
  EuiLink,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPage,
  EuiHeaderLink,
} from "@elastic/eui";
import { FunctionComponent } from "react";
import { Switch, useHistory } from "react-router-dom";
import "./App.scss";
import { routes } from "./routes";
import { RouteWithSubRoutes, getLinks } from "./utils/routing";

const App: FunctionComponent = () => {
  const history = useHistory();
  const links = getLinks(history);

  return (
    <div>
      <EuiHeader
        theme="dark"
        sections={[
          {
            items: [
              <EuiFlexGroup gutterSize="m" alignItems="center">
                <EuiFlexItem grow={false}>
                  <EuiLink href="https://ninerealms.capital">
                    <img
                      src="/nr_logo.png"
                      loading="lazy"
                      style={{
                        height: "28px",
                        marginLeft: "10px",
                        marginTop: "4px",
                      }}
                      alt=""
                    />
                  </EuiLink>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiLink href="https://thorchain.com">
                    <img
                      src="/tc_logo.png"
                      loading="lazy"
                      style={{ height: "22px", marginTop: "4px" }}
                      alt=""
                    />
                  </EuiLink>
                </EuiFlexItem>
              </EuiFlexGroup>,
            ],
            borders: "none",
          },
          {
            items: links.map(({ id, icon, label, onClick }) => {
              return (
                <EuiHeaderLink
                  color="ghost"
                  key={id}
                  iconType={icon}
                  onClick={onClick}
                >
                  {label}
                </EuiHeaderLink>
              );
            }),
          },
        ]}
      />

      <EuiPage>
        <Switch>
          {routes
            .map((route, i) => <RouteWithSubRoutes key={i} {...route} />)
            .concat(
              <RouteWithSubRoutes
                key={routes.length}
                {...{ ...routes[0], path: "/" }}
              />
            )}
        </Switch>
      </EuiPage>
    </div>
  );
};

export default App;
