import Health from "./health/health";
import Links from "./links/links";
import Mimir from "./mimir/mimir";
import Monitor from "./monitor/monitor";
import Network from "./network/network";
import Nodes from "./nodes/nodes";
import Security from "./security/security";
import Tools from "./tools/tools";
import Vaults from "./vaults/vaults";

export const routes = [
  {
    id: "network",
    label: "Network",
    path: "/network",
    icon: "online",
    component: Network,
  },
  {
    id: "nodes",
    label: "Nodes",
    path: "/nodes",
    icon: "compute",
    component: Nodes,
  },
  {
    id: "mimir",
    label: "Mimir",
    path: "/mimir",
    icon: "flag",
    component: Mimir,
  },
  {
    id: "health",
    label: "Health",
    path: "/health",
    icon: "heart",
    component: Health,
  },
  {
    id: "security",
    label: "Security",
    path: "/security",
    icon: "reporter",
    component: Security,
  },
  {
    id: "vaults",
    label: "Vaults",
    path: "/vaults",
    icon: "lock",
    component: Vaults,
  },
  {
    id: "monitor",
    label: "Monitor",
    path: "/monitor",
    icon: "securitySignal",
    component: Monitor,
  },
  {
    id: "tools",
    label: "Tools",
    path: "/tools",
    icon: "wrench",
    component: Tools,
  },
  {
    id: "links",
    label: "Links",
    path: "/links",
    icon: "link",
    component: Links,
  },
];
